<template>
  <div :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
    <vx-card>
      <vs-tabs alignment="fixed" v-model="activeTab">
        <div class="my-2">
          <vs-tab label="Customer"
                  icon-pack="feather"
                  :icon="errors.tabCustomer.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabCustomer.isError ? 'danger' : null">
            <TabCustomer :initData="initDataTabCustomer"/>
          </vs-tab>

          <vs-tab label="Booking & Properti"
                  icon-pack="feather"
                  :icon="errors.tabProperti.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabProperti.isError ? 'danger' : null">
            <TabProperti :initData="initDataTabProperti"/>
          </vs-tab>

          <vs-tab label="Angsuran"
                  icon-pack="feather"
                  :icon="errors.tabAngsuran.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabAngsuran.isError ? 'danger' : null">
            <TabAngsuran :initData="initDataTabAngsuran"/>
          </vs-tab>

          <vs-tab label="Signature"
                  icon-pack="feather"
                  :icon="errors.tabSignature.isError ? 'icon-alert-triangle' : null"
                  :tab-color="errors.tabSignature.isError ? 'danger' : null">
            <TabSignature :initData="initDataTabSignature"/>
          </vs-tab>
        </div>
      </vs-tabs>
    </vx-card>

    <div class="vx-card mt-5" v-if="activeTab === 3">
      <div class="w-full flex justify-end">
        <vs-button :disabled="isLoading || !isAnyUnsavedChanges" icon-pack="feather" icon="icon-save" class="w-full" color="primary" @click="save">
          <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
          <span v-if="!isLoading">Simpan</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import BookingRepository from '@/repositories/marketing/booking-repository'
import TabCustomer from '@/views/pages/marketing/booking/tabs/TabCustomer'
import TabProperti from '@/views/pages/marketing/booking/tabs/TabProperti'
import TabAngsuran from '@/views/pages/marketing/booking/tabs/TabAngsuran'
import TabSignature from '@/views/pages/marketing/booking/tabs/TabSignature'
import moduleBookingAdd from '@/store/modules/marketing/booking/booking-add.store'
import { convertToFormData } from '@/utilities/common/global-methods'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'BookingAdd',
  components: {
    TabSignature,
    TabProperti,
    TabCustomer,
    TabAngsuran
  },
  mounted () {
    this.$store.commit('marketing/bookingAdd/SET_ANY_UNSAVED_CHANGES', false)
    this.$store.commit('marketing/bookingAdd/CLEAR_ERRORS')
    this.listenKeyboardPressed()
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      isLoadingInitData: false,
      initDataTabCustomer: null,
      initDataTabProperti: null,
      initDataTabAngsuran: null,
      initDataTabSignature: null
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.marketing.bookingAdd.activeTab
      },
      set (value) {
        this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      errors: state => state.marketing.bookingAdd.errors,
      isAnyUnsavedChanges: state => state.marketing.bookingAdd.isAnyUnsavedChanges,
      dataTabCustomer: state => state.marketing.bookingAdd.tabCustomer,
      dataTabProperti: state => state.marketing.bookingAdd.tabProperti,
      dataTabAngsuran: state => state.marketing.bookingAdd.tabAngsuran,
      dataTabSignature: state => state.marketing.bookingAdd.tabSignature
    })
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true

      const idBooking = this.$route.params.idBooking
      const params = {
        withUnitInformation: 1,
        withCustomerInformation: 1,
        withAngsurans: 1
      }
      BookingRepository.show(idBooking, params)
        .then(response => {
          this.buildInitData(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    buildInitData (initData) {
      // =====TAB CUSTOMER===== //
      const initDataTabCustomer = {
        customer: initData.customer,
        aktifitasMarketing: {
          id_aktifitas_marketing: initData.id_aktifitas_marketing,
          no_bukti: initData.no_bukti_aktifitas_marketing
        }
      }

      // =====TAB PROPERTI===== //
      const initDataTabProperti = {
        no_booking: initData.no_kontrak,
        nama_atasan_staf: initData.nama_staf_atasan,
        unit: {
          ...initData.properti_unit,
          harga_standar: initData.harga_standar,
          harga_luas: initData.harga_luas,
          harga_tinggi: initData.harga_tinggi,
          harga_view: initData.harga_view,
          harga_mutu: initData.harga_mutu,
          biaya_adm: initData.biaya_adm,
          biaya_notaris: initData.biaya_notaris
        },
        ..._.pick(initData, [
          'tgl',
          'id_staf',
          'nama_staf',
          'keterangan_booking',
          'id_proyek',
          'id_properti_unit'
        ])
      }

      // =====TAB ANGSURAN===== //
      const initDataTabAngsuran = {
        unit: initDataTabProperti.unit,
        ..._.pick(initData, [
          'id_cara_bayar',
          'id_bank_kpr',
          'id_coa_debet',
          'nama_coa_debet',
          'diskon_persen',
          'diskon_jml',
          'ppn_jenis',
          'ppn_key',
          'ppn_persen',
          'ppn_jml',
          'pph_nama',
          'pph_key',
          'pph_persen',
          'pph_jml',
          'id_cara_bayar_bf',
          'booking_fee_due',
          'booking_fee_status',
          'booking_fee',
          'dp_persen',
          'dp_jml',
          'termin_dp',
          'termin_angsuran',
          'angsuran_start_due'
        ]),
        angsurans: initData.angsurans
      }

      // =====TAB SIGNATURE===== //
      const initDataTabSignature = {
        signatureDataUrl: initData.file_customer_signature_url
      }

      this.initDataTabCustomer = initDataTabCustomer
      setTimeout(() => {
        this.initDataTabProperti = initDataTabProperti
        this.initDataTabAngsuran = initDataTabAngsuran
        this.initDataTabSignature = initDataTabSignature
      }, 600)
    },

    save () {
      this.$store.commit('marketing/bookingAdd/CLEAR_ERRORS')
      this.isLoading = true

      const params = this.buildParams()
      console.log(params)
      BookingRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.$store.commit('marketing/bookingAdd/SET_ERRORS', errors)
              this.notifyError('Data tidak valid, mohon periksa kembali.')
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      // general
      const idBooking = this.$route.params.idBooking

      // tab customer
      const tabCustomer = {}
      tabCustomer.aktifitasMarketing = {
        id: this.dataTabCustomer.aktifitasMarketing.id_aktifitas_marketing
      }
      tabCustomer.customer = _.pick(this.dataTabCustomer.customer, ['id', 'id_proyek', 'id_profesi', 'nama', 'no_identitas', 'gender', 'tmp_lahir', 'tgl_lahir', 'domisili', 'alamat', 'kelurahan', 'kota', 'pos', 'telp', 'hp', 'email', 'agama', 'status_profesi', 'npwp', 'alamat_npwp', 'alamat_surat', 'no_rekening', 'jabatan', 'nama_instansi', 'telp_instansi', 'fax_instansi', 'alamat_instansi', 'cp_instansi', 'jenis_usaha', 'sumber_dana', 'tujuan_pembelian', 'status_nikah', 'nama_pasangan', 'status_tmptgl', 'pengh_tetap', 'pengh_tambah', 'pengh_bulanan'])

      // tab properti
      const tabProperti = _.pick(this.dataTabProperti, ['id_proyek', 'id_properti_unit', 'id_staf', 'no_booking', 'tgl', 'keterangan_booking'])

      // tab angsuran
      const tabAngsuran = _.pick(this.dataTabAngsuran, ['id_cara_bayar', 'id_cara_bayar_bf', 'id_bank_kpr', 'id_coa_debet', 'diskon_persen', 'diskon_jml', 'ppn_persen', 'ppn_jml', 'ppn_jenis', 'ppn_key', 'pph_persen', 'pph_jml', 'pph_nama', 'pph_key', 'totalHargaJual', 'dp_persen', 'dp_jml', 'booking_fee', 'booking_fee_status', 'booking_fee_due', 'termin_dp', 'termin_angsuran', 'angsuran_start_due', 'unit', 'angsurans', 'totalSisaAngsuran', 'dpp', 'grandTotal', 'isRefreshTerminNeeded'])
      tabAngsuran.unit = _.pick(this.dataTabAngsuran.unit, ['harga_standar', 'harga_ajb', 'harga_luas', 'harga_tinggi', 'harga_view', 'harga_mutu', 'harga_ppn', 'biaya_adm', 'biaya_notaris', 'biaya_bphtb', 'biaya_lain1', 'biaya_lain2', 'biaya_lain3', 'harga_min', 'harga_max', 'totalHargaUnit', 'dpp'])
      tabAngsuran.angsurans = _.map(tabAngsuran.angsurans, (item) => _.pick(item, ['id', 'tipe_angsuran', 'no_termin', 'tgl_due', 'persen', 'jml_nominal', 'dpp', 'jml_ppn', 'jml_pph', 'jml_bayar', 'keterangan', 'dpp']))

      // tab signature
      const tabSignature = { signature: this.dataTabSignature.signatureBlob }

      const params = { id_booking: idBooking, tabCustomer, tabProperti, tabAngsuran, tabSignature }
      return convertToFormData(params)
    },

    onSuccess () {
      this.$store.commit('marketing/bookingAdd/SET_ANY_UNSAVED_CHANGES', false)
      this.notifySuccess('Data berhasil disimpan.')
      this.$router.push({ name: 'marketing.booking' })
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    listenKeyboardPressed () {
      window.addEventListener('keypress', e => this.onKeyboardPressed())
    },

    onKeyboardPressed: _.debounce(function () {
      this.$store.commit('marketing/bookingAdd/SET_ANY_UNSAVED_CHANGES', true)
    }, 500)
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'bookingAdd'], moduleBookingAdd)
  },
  beforeDestroy () {
    this.$store.commit('marketing/bookingAdd/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'bookingAdd'])
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAnyUnsavedChanges) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>
